import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Style } from "../components/Philosophie/styled";

export default function Philosophie({ data: { marguerite }, location }) {
  return (
    <Layout
      location={location}
      title={"Gartenbau Dannenfelser | Offene Stellen"}
      description={
        "Als erfolgreiches, regional ansässiges Garten- und Landschaftsbauunternehmen, sehen wir unsere Stärken in unserer langjährigen Erfahrung im Umgang mit Mensch, Material und Natur. Wir arbeiten zuverlässig, verantwortungs- und kostenbewusst.  Ihr Projekt liegt uns am Herzen.  Rufen Sie uns zur unverbindlichen Terminvereinbarung an, oder schreiben Sie uns eine E-Mail.  Wir freuen uns auf Sie!"
      }
    >
      <Style>
        <section>
          <h1 className={"lightgreen"}>Kompetenz in Grün</h1>
          <p style={{ marginBottom: "0" }}>
            Als erfolgreiches, regional ansässiges Garten- und
            Landschaftsbauunternehmen, sehen wir unsere Stärken in unserer
            langjährigen Erfahrung im Umgang mit Mensch, Material und Natur. Wir
            arbeiten zuverlässig, verantwortungs- und kostenbewusst.
          </p>

          <p className={"bold"}>Ihr Projekt liegt uns am Herzen.</p>

          <p className={"bold"}>
            Rufen Sie uns zur unverbindlichen Terminvereinbarung an, oder
            schreiben Sie uns eine{" "}
            <a href={"mailto:verwaltung@gartenbau-dannenfelser.de"}>E-Mail</a>.
          </p>

          <p className={"bold"}>Wir freuen uns auf Sie!</p>
        </section>

        <div className={"image-wrapper"}>
          <GatsbyImage
            image={getImage(marguerite)}
            alt={"Margeriten auf einer Wiese"}
            className={"image"}
          />
        </div>
      </Style>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    marguerite: file(relativePath: { eq: "marguerite.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;
