import styled from "styled-components";

export const Style = styled.div`
  .image-wrapper {
    overflow: hidden;
  }

  .image {
    width: 100%;

    transition: all 500ms ease-in-out;

    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }
`;
